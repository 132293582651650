import React from "react";

import { useTranslation } from "react-i18next";
const Hero = () => {
  
const { t, i18n } = useTranslation();
  return (
    <div className="pt-24 pt-md-26 pt-lg-30 ">
      <div className="container">
          <div className="row justify-content-center">
            <div
              className="mb-11 mb-lg-24"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h2 className="font-size-11 mb-5 text-center">{t("about_title")}</h2>
              <p className="font-size-7 mb-0 text-center">
              {t("about_subtitle")}
              </p><br/><br/>
              <p className="heading-default-color font-size-6 mb-5 mb-lg-9 mx-lg-14 mx-xl-14 col-xl-10 col-lg-10 col-md-10 col-xs-10">
              {t("about_paragraph1")}
               </p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Hero;
