import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import TeamMemberCard from "../../components/TeamMemberCard";
import img1 from "../../assets/image/inner-page/png/plamen-img.png";
import img2 from "../../assets/image/inner-page/png/kristo-img.png";
import img3 from "../../assets/image/inner-page/png/boryana-img.png";

const items = [
  {
    image: img1,
    name: "about_team_heading1",
    title: "about_team_subtitle1",
    description: "about_team_paragraph1",
    linkedin: "https://linkedin.com/in/plamenkostov/",
  },
  {
    image: img2,
    name: "about_team_heading2",
    title: "about_team_subtitle2",
    description: "about_team_paragraph2",
    linkedin: "https://linkedin.com/in/kristoprifti93/",
  },
];

const Team = () => {
  
const { t, i18n } = useTranslation();
  return (
    <div className="pb-15 pb-md-19 pb-lg-24">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-sm-10">
            <div
              className="text-center mb-9 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <h2 className="font-size-10 mb-0">
                {t("about_heading2")}
              </h2>
            </div>
          </div>
        </div>
        {/* Teammember card */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-lg-4 col-md-6 col-xs-9"
              data-aos="zoom-in"
              data-aos-duration={800}
              key={index}
            >
              <TeamMemberCard options={item} />
            </div>
          ))}
        </div>
        {/* Teammember card */}
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="text-center pt-7 pt-md-12 pt-lg-16">
              <h2
                className="font-size-9 mb-9"
                data-aos="fade-up"
                data-aos-duration={500}>
                  {t("about_team_title")}
              </h2>
             <Link to="/career"><a
                className="btn btn-golden-yellow btn-2 rounded-5"
                data-aos="fade-up"
                data-aos-duration={800}
              > {t("about_team_button")}
              </a></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
